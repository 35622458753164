// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-about-js": () => import("./../../../src/pages/art/about.js" /* webpackChunkName: "component---src-pages-art-about-js" */),
  "component---src-pages-art-contact-js": () => import("./../../../src/pages/art/contact.js" /* webpackChunkName: "component---src-pages-art-contact-js" */),
  "component---src-pages-art-cv-js": () => import("./../../../src/pages/art/cv.js" /* webpackChunkName: "component---src-pages-art-cv-js" */),
  "component---src-pages-art-index-js": () => import("./../../../src/pages/art/index.js" /* webpackChunkName: "component---src-pages-art-index-js" */),
  "component---src-pages-art-paintings-js": () => import("./../../../src/pages/art/paintings.js" /* webpackChunkName: "component---src-pages-art-paintings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-painting-js": () => import("./../../../src/templates/painting.js" /* webpackChunkName: "component---src-templates-painting-js" */)
}

