export default {
    breakpoints: ['576px', '768px', '992px', '1200px'],
    colors: {
        text: '#333333',
        textLight: '#b0b0b0',
        heading: '#070403',
        accent: '#FF8460',
        accentDark: '#bd380f',
        modalOverlay: 'rgba(0, 0, 0, 0.8)',
        bgLight: 'rgba(255, 255, 255, 0.9)',
        bgDark: 'rgba(0, 0, 0, 0.9)',
    },
    fonts: {
        body: 'Open Sans, system-ui, sans-serif',
        heading: 'Unica One, system-ui, sans-serif',
        monospace: 'Menlo, monospace',
    },
    fontWeights: {
        body: 400,
        heading: 400,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125,
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    sizes: {
        sideBar: 300,
        prose: '66ch',
    },
    radii: [0, 4],
    shadows: [
        'none',
        '0 10px 15px -3px rgba(0, 0, 0, .10), 0 4px 6px -2px rgba(0, 0, 0, .05)'
    ],
}
